import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", {
    attrs: {
      "title": "Add Gondola Log",
      "class-name": "dlg-gondolalog"
    }
  }, [_c("select-input", {
    key: "gondolaId",
    attrs: {
      "label": "Gondola Code",
      "reference": "grower.common.gondolas",
      "source": "id",
      "source-label": "description",
      "span": 12
    }
  }), _c("select-input", {
    key: "tubId",
    attrs: {
      "label": "Tub",
      "reference": "grower.common.tubs-truck",
      "source": "id",
      "source-label": "code",
      "span": 12
    }
  }), _c("select-input", {
    key: "paddockId",
    attrs: {
      "label": "Paddock Code",
      "reference": "grower.common.paddock",
      "source": "id",
      "source-label": _vm.paddockCodeLabel,
      "span": 12
    }
  }), _c("text-input", {
    key: "harvester",
    attrs: {
      "label": "Harvester",
      "span": 12
    }
  }), _c("text-input", {
    key: "timeCalled",
    attrs: {
      "label": "Time Called",
      "span": 12,
      "value": _vm.timeCall.format("D/M/Y - h:mm:ss A"),
      "disabled": true
    }
  }), _c("checkbox-input", {
    key: "rtCalled",
    attrs: {
      "class-name": "pt-3",
      "label": "RT Called",
      "span": 12
    }
  })], 1);
};
var staticRenderFns$1 = [];
var CreateGondolaLog_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "CreateGondolaLog",
  inject: ["crud"],
  data() {
    return { timeCall: this.$moment(), disposition: "E" };
  },
  created() {
    this.storeValue("timeCalled", this.timeCall);
  },
  methods: {
    paddockCodeLabel(option) {
      if (!option.description)
        return option.code;
      return `${option.code} ${option.description}`;
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "62e5abab", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateGondolaLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.common.gondolas",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.tubs-truck",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.paddock",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.gondola-logs",
      "redirect-route": "/watties-grower/gondola-log",
      "api-url": _vm.apiUrl
    }
  }, [_c("create-gondola-log")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateGondolaLog
  },
  data() {
    return { apiUrl };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
